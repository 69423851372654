import React, { useRef } from "react";
import { FaDownload } from "react-icons/fa";

function TableWithDownload({ children }) {
  const tableRef = useRef(null);

  const downloadTableAsCSV = () => {
    if (tableRef.current) {
      const table = tableRef.current;
      const rows = table.getElementsByTagName("tr");
      let csvContent = "data:text/csv;charset=utf-8,";

      const columnNames = [];
      const headerRow = rows[0];
      const headerCells = headerRow.getElementsByTagName("th");

      // Extract column names
      for (let j = 0; j < headerCells.length; j++) {
        const columnName = headerCells[j].innerText;
        columnNames.push(escapeCSVValue(columnName));
      }

      csvContent += columnNames.join(",") + "\n";

      // Loop through rows
      for (let i = 1; i < rows.length; i++) {
        const cells = rows[i].getElementsByTagName("td");
        const rowContent = [];

        // Loop through cells
        for (let j = 0; j < cells.length; j++) {
          rowContent.push(escapeCSVValue(cells[j].innerText));
        }

        csvContent += rowContent.join(",") + "\n";
      }

      // Create a download link
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "table_data.csv");
      document.body.appendChild(link); // Append the link to the document
      link.click(); // Simulate a click on the link to start the download
    }
  };

  const escapeCSVValue = (value) => {
    if (value.includes(",") || value.includes('"')) {
      return '"' + value.replace(/"/g, '""') + '"';
    }
    return value;
  };

  const handleDownloadClick = () => {
    downloadTableAsCSV();
  };

  return (
    <div ref={tableRef} style={{ position: "relative" }}>
      <button className="table-download" onClick={handleDownloadClick}>
        <FaDownload></FaDownload>
      </button>
      {children}
    </div>
  );
}

export default TableWithDownload;
