import { useState } from "react";
import "./AudioHandler.css"

function useAudioHandler(
  setUserInput,
  handleUserInput,
  setInputSource,
  setCurrentMessageIndex,
  messages
) {
  const [isListening, setIsListening] = useState(false);
  const recognition = new window.webkitSpeechRecognition();
  const [isReading, setIsReading] = useState(false);

  function startSpeechRecognition() {
    recognition.lang = "en-US";

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.onerror = () => {
      setIsListening(false);
    };

    recognition.onresult = (event) => {
      const speechToText = event.results[0][0].transcript;
      setUserInput(speechToText);
      handleUserInput({ key: "Enter" });
    };

    recognition.start();
  }

  function handleSpeechRecognition() {
    if (isListening) {
      recognition.stop();
      setInputSource("text"); // Set input source to "text" when stopping speech recognition
    } else {
      startSpeechRecognition();
      setInputSource("audio"); // Set input source to "audio" when starting speech recognition
    }
  }

  function handleReadOut(message) {
    if (isReading) {
      setIsReading(false); // Stop the readout if it's already in progress
      setInputSource("text"); // Reset the input source to "text"
      window.speechSynthesis.cancel(); // Stop the speech synthesis
      setCurrentMessageIndex(-1); // Reset the current message index
      return;
    }

    setIsReading(true); // Set the reading state to true

    const utterance = new SpeechSynthesisUtterance(message);
    utterance.lang = localStorage.getItem('dma_language') || 'en-us'

    utterance.onstart = () => {
      const messageIndex = messages.findIndex(
        (msg) => msg.text === message && !msg.user
      );
      setCurrentMessageIndex(messageIndex);
    };

    utterance.onend = () => {
      setIsReading(false); // Set the reading state back to false when the speech ends
      setCurrentMessageIndex(-1); // Reset the current message index
      setInputSource("text"); // Reset the input source to "text"
    };

    window.speechSynthesis.speak(utterance);
  }

  return {
    isListening,
    handleSpeechRecognition,
    handleReadOut,
  };
}

export default useAudioHandler;
