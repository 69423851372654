import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  FaRobot,
  FaStop,
  FaVolumeUp,
  FaTrash,
  FaPen,
  FaCheck,
  FaPaperPlane,
  FaAngleDoubleRight,
  FaAngleDoubleLeft,
  FaEllipsisV,
  FaRegCopy,
  FaRegTrashAlt,
  FaRegShareSquare,
} from "react-icons/fa";
import { BiImageAdd } from "react-icons/bi";
import { LiaFileUploadSolid, LiaMicrophoneSolid } from "react-icons/lia";
import { GoUpload } from "react-icons/go";
import { v4 as uuidv4 } from "uuid";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import useAudioHandler from "./AudioHandler";
import "./table.css";
import "./chat/MessageBuble.css";
import "./chat/uploadItems.css";
import BarChart from "./Chart";
import TableWithDownload from "./TableWithDownload";
import sendEmail from "./EmailShareComponent";
import DefaultTexts from "./chat/DefaultTexts";

function ChatWindow({ user, language }) {
  const [sessionId, setSessionId] = useState(null);
  const userInitial = user?.name
    ? user.name
        .split(" ")
        .map((name) => name.charAt(0).toUpperCase())
        .reverse()
        .join("")
    : "";
  // State Hooks
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const toggleNav = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const [messages, setMessages] = useState([]);

  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isFirstRequest, setIsFirstRequest] = useState(false);
  const [textAreaHeight, setTextAreaHeight] = useState(60);
  const [inputSource, setInputSource] = useState("text");
  const [currentMessageIndex, setCurrentMessageIndex] = useState(-1);
  const [conversation, setConversation] = useState([]);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editedItemName, setEditedItemName] = useState("");
  const [loadingConversations, setLoadingConversations] = useState(false);
  const [originalSuggestions] = useState(
    DefaultTexts[language || "en"].SampleQuestions
  );
  const [suggestions, setSuggestions] = useState(originalSuggestions);
  // const [selectedConvId, setSelectedConvId] = useState(null);

  // Ref Hooks
  const messageEndRef = useRef(null);
  const userInputRef = useRef(null);
  const sessionIdRef = useRef(null);

  // Custom Hooks
  const {
    isListening,
    handleSpeechRecognition,
    handleReadOut,
  } = useAudioHandler(
    setUserInput,
    handleUserInput,
    setInputSource,
    setCurrentMessageIndex,
    messages
  );

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [user?.name, messages]);

  function generateNewSessionId() {
    return uuidv4();
  }
  function renderBarChart(chartDataString, chart) {
    return <BarChart chartDataString={chartDataString} chart={chart} />;
  }
  // left nav

  async function handleConversationClick(convId) {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/get-history-api?conversation_id=${convId}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data) {
        setSessionId(convId);
        setMessages(data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      // Handle error, show error message to the user or retry the API call if necessary
    }
  }
  async function updateConversationName(id, newName) {
    setLoading(true);
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }/v1/update-conversation-name?conversation_id=${id}&name=${encodeURIComponent(
          newName
        )}`,
        {
          method: "PUT",
        }
      );

      if (!response.ok) {
        setLoading(false);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Handle successful update if needed
    } catch (error) {
      setLoading(false);
      console.error(error);
      // Handle error, show error message to the user or retry the API call if necessary
    }
    setLoading(false);
  }

  const handleNewChat = useCallback(
    (newSessionId) => {
      setIsFirstRequest(true);

      sessionIdRef.current = newSessionId;

      setSessionId(newSessionId);

      const newChat = {
        name: DefaultTexts[language || "en"].NewChatName,
        id: newSessionId,
      };
      setConversation((prevConversation) => [newChat, ...prevConversation]);

      setSuggestions(originalSuggestions);

      setMessages([
        {
          id: 0,
          role: "assistant",
          message:
            DefaultTexts[language || "en"].Greeting +
            ` ${user?.name.split(" ")[1]} ! \n` +
            DefaultTexts[language || "en"].WelcomeMessage,
        },
      ]);
    },
    [user, originalSuggestions, language]
  );

  useEffect(() => {
    // Fetch the conversations from the API when the component mounts
    async function fetchConversations() {
      setLoadingConversations(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/conversation?user_id=${user?.username}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data) {
          setLoadingConversations(false);
          setConversation((prevConversation) => {
            const newConversations = data.conversations.filter(
              (conversation) => {
                // Check if the conversation is already present in the previous conversation state
                return !prevConversation.some(
                  (prevConv) => prevConv.id === conversation.id
                );
              }
            );

            return [...prevConversation, ...newConversations];
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchConversations();
  }, [user]);

  useEffect(() => {
    // This will run only once when the component is mounted or when handleNewChat changes
    if (!sessionIdRef.current) {
      sessionIdRef.current = generateNewSessionId();
      handleNewChat(sessionIdRef.current);
    }
  }, [handleNewChat]); // Added handleNewChat to the dependency array

  async function handleChatDelete(event, id) {
    event.stopPropagation();
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/v1/remove-chat?chat_id=${id}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // Code for handling successful reset
    } catch (error) {
      // Code for handling error during reset
    }
    setLoading(false);

    if (id === sessionId) {
      handleNewChat(generateNewSessionId());
    }

    setConversation(conversation.filter((message) => message.id !== id));
  }

  function startEditingItem(event, itemId, itemName) {
    event.stopPropagation();
    setEditingItemId(itemId);
    setEditedItemName(itemName);
  }

  async function handleRenameItem(event, itemId) {
    event.stopPropagation();

    event.preventDefault();

    // Call the API to update the conversation name in the database
    await updateConversationName(itemId, editedItemName);

    // Update the chat item with the new name in the local state
    const updatedConversation = conversation.map((message) =>
      message.id === itemId ? { ...message, name: editedItemName } : message
    );
    setConversation(updatedConversation);
    setEditingItemId(null);
    setEditedItemName("");
  }

  // chat input

  function handleSuggestionClick(suggestion) {
    setUserInput(suggestion);
  }

  function handleInputChange(event) {
    const userInput = event.target.value;
    setUserInput(userInput);

    // Filter and set suggestions based on user input
    const filteredSuggestions = originalSuggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(userInput.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  }

  async function submitUserInput(userMessage) {
    const nextId = messages[messages.length - 1].id + 1;

    const newMessages = [
      ...messages,
      { id: nextId, role: "user", message: userMessage },
    ];

    const isNewChat = isFirstRequest;
    setMessages(newMessages);
    setUserInput("");
    setTextAreaHeight(60);

    setLoading(true);

    // Check if it's the first user query
    if (isFirstRequest) {
      // Get the first character of the user query as the chat name
      const newName = userMessage.slice(0, 18);

      // Update the chat item with the new name
      const updatedConversation = conversation.map((message) =>
        message.id === sessionId ? { ...message, name: newName } : message
      );
      setConversation(updatedConversation);

      // Set isFirstRequest to false after handling the first request
      setIsFirstRequest(false);
    }

    try {
      const chatPromise = fetch(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }/v1/chat?query=${encodeURIComponent(userMessage)}&user_id=${
          user.username
        }&session_id=${sessionId}&new_chat=${isNewChat}&lan=${localStorage.getItem(
          "dma_language"
        ) || "en"}&id=${nextId}`
      ).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      });

      const suggestionsPromise = fetch(
        `${
          process.env.REACT_APP_API_ENDPOINT
        }/v1/chat-suggestions?query=${encodeURIComponent(
          userMessage
        )}&user_id=${user.username}`
      ).then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      });

      const [botMessage, suggestions] = await Promise.all([
        chatPromise,
        suggestionsPromise,
      ]);

      if (typeof botMessage.message === "string") {
        const mailTriggerRegex = /send_mail\((?:[^)(]|\((?:[^)(]+|\([^)(]*\))*\))*\)/;
        const mailTrigger = botMessage.message.match(mailTriggerRegex);
        const restOfMessage = botMessage.message
          .replace(mailTriggerRegex, "")
          .trim();

        if (restOfMessage) {
          newMessages.push({
            id: nextId + 1,
            role: "assistant",
            message: restOfMessage,
          });
        }

        if (mailTrigger) {
          sendEmail(mailTrigger[0]);
          newMessages.push({
            id: nextId + 1,
            role: "assistant",
            message: "✉️ Mail has been drafted.",
          });
        }

        if (inputSource === "audio") {
          handleReadOut(restOfMessage); // Read out the chatbot message when input source is "audio"
        }
      } else {
        newMessages.push({
          role: "assistant",
          message: "Oops! Something went wrong. Please try again later.",
        });
      }
      setMessages(newMessages);
      // Update the suggestions from the API response
      if (Array.isArray(suggestions.suggestions)) {
        setSuggestions(suggestions.suggestions);
      } else {
        setSuggestions(originalSuggestions);
      }
    } catch (error) {
      console.error(error);
      newMessages.push({
        role: "assistant",
        message: "Oops! Something went wrong. Please try again later.",
      });
      setMessages(newMessages);
    }

    setLoading(false);
  }

  async function handleUserInput(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      const userMessage = event.target.value;
      await submitUserInput(userMessage);
    }
  }

  async function handleSendIconClick() {
    if (userInput) {
      await submitUserInput(userInput);
      setUserInput("");
      setTextAreaHeight(60);
    }
  }

  function SendIcon({ loading }) {
    return (
      <div className="send-icon" onClick={handleSendIconClick}>
        {loading ? (
          <div className="loader"></div>
        ) : (
          <FaPaperPlane className="send-icon" />
        )}
      </div>
    );
  }

  function UpcomingFeature() {
    alert(
      "Oops! The feature you're trying to access is still in development. We're working hard to bring you the latest and greatest features. Stay tuned for updates, and thank you for your patience!"
    );
  }

  async function handleDeleteMessage(messageId) {
    setLoading(true);

    const confirmation = window.confirm(
      "Are you sure you want to delete this message?"
    );

    if (confirmation) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/messages?conversation_id=${sessionId}&message_id=${messageId}`,
          {
            method: "DELETE",
          }
        );

        if (response.ok) {
          const indexToDelete = messages.findIndex(
            (message) => message.id === messageId
          );

          const updatedMessages = messages.filter(
            (_, index) => index !== indexToDelete
          );

          setMessages(updatedMessages);
        } else {
          console.error("Failed to delete message");
        }
      } catch (error) {
        console.error("Error deleting message:", error);
      }
    }

    setLoading(false);
  }

  const inputRef = useRef(null);
  const handleImageUpload = async (e) => {
    const nextId = messages[messages.length - 1].id + 1;
    const file = e.target.files[0];
    const isNewChat = isFirstRequest;

    setLoading(true);

    if (file) {
      if (isFirstRequest) {
        const newName = `${file.name} image analysis`.slice(0, 20);

        const updatedConversation = conversation.map((message) =>
          message.id === sessionId ? { ...message, name: newName } : message
        );
        setConversation(updatedConversation);

        setIsFirstRequest(false);
      }

      try {
        const formData = new FormData();
        formData.append("user_id", user.username);
        formData.append("file_name", file.name);
        formData.append("session_id", sessionId);
        formData.append("message_id", nextId);
        formData.append("image", file);
        formData.append("new_chat", isNewChat);

        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/v2/upload_image`,
          {
            method: "POST",
            body: formData,
          }
        );

        const reader = response.body.getReader();

        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            break;
          }

          const textValue = new TextDecoder("utf-8").decode(value);

          try {
            const jsonObjects = textValue.split("!NXT!");

            jsonObjects.forEach(async (jsonObject) => {
              if (jsonObject !== "") {
                const parsedObject = JSON.parse(jsonObject);

                if (parsedObject.hasOwnProperty("content")) {
                  setMessages((prevMessages) => {
                    const lastMessage = prevMessages[prevMessages.length - 1];
                    lastMessage.message += parsedObject.content;
                    return [...prevMessages];
                  });
                } else if (parsedObject.hasOwnProperty("blob_url")) {
                  setMessages((prevMessages) => [
                    ...prevMessages,
                    {
                      id: nextId,
                      role: "user",
                      message:
                        "```img\n" +
                        sessionId +
                        "/" +
                        nextId +
                        "." +
                        file.name.split(".").pop() +
                        "```",
                    },
                    {
                      id: nextId + 1,
                      role: "assistant",
                      message: "",
                    },
                  ]);
                }
              }
            });
          } catch (error) {
            console.error("JSON Parsing Error:", error);
          }
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="chat-container">
      <div className={`chat-nav ${isNavCollapsed ? "collapsed" : ""}`}>
        <div className="new-chat">
          <button
            className="add-new-chat"
            onClick={() => handleNewChat(generateNewSessionId())}
          >
            + {DefaultTexts[language || "en"].NewChatName}
          </button>
        </div>
        <div className="chat-history">
          <p>{DefaultTexts[language || "en"].History}</p>
          {loadingConversations ? (
            <div className="loader2">
              <div className="dot1"></div>
              <div className="dot2"></div>
              <div className="dot3"></div>
            </div>
          ) : (
            conversation?.map((message) => (
              <div
                key={message.id}
                className={`conv-hst-item ${
                  message.id === sessionId ? "active" : ""
                }`}
                onClick={() => handleConversationClick(message.id)}
              >
                {editingItemId === message.id ? (
                  <form
                    onSubmit={(event) => handleRenameItem(event, message.id)}
                  >
                    <input
                      type="text"
                      value={editedItemName}
                      onChange={(event) =>
                        setEditedItemName(event.target.value)
                      }
                      onClick={(event) => event.stopPropagation()}
                    />
                    <button
                      type="submit"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <FaCheck />
                    </button>
                  </form>
                ) : (
                  <>
                    {message.name}
                    <span className="conv-hist-item-icons">
                      <span className="pen">
                        <FaPen
                          onClick={(event) =>
                            startEditingItem(event, message.id, message.name)
                          }
                        />
                      </span>
                      <span
                        onClick={(event) => handleChatDelete(event, message.id)}
                        className="trash"
                      >
                        <FaTrash />
                      </span>
                    </span>
                  </>
                )}
              </div>
            ))
          )}
        </div>
      </div>
      <div className="chat-view">
        <button className="toggle-button" onClick={toggleNav}>
          {isNavCollapsed ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}
        </button>
        <div className="chat-messages">
          {messages.map((message, index) => (
            <div
              key={message.id}
              className={`message-bubble ${message.id} ${
                message.role === "user" ? "user" : "bot"
              } ${message.id === currentMessageIndex ? "reading" : ""}`}
            >
              <div className="message-icon">
                {message.role === "user" ? (
                  <div className="user-initial">{userInitial}</div>
                ) : (
                  <FaRobot />
                )}
              </div>

              <div className="message-text">
                {(message.message !== undefined ? message.message : "")
                  .split("```")
                  .map((text, subIndex) => {
                    const mailTriggerRegex = /send_mail\((?:[^)(]|\((?:[^)(]+|\([^)(]*\))*\))*\)/;
                    const mailTrigger = text.match(mailTriggerRegex);
                    const restOfMessage = text
                      .replace(mailTriggerRegex, "")
                      .trim();
                    if (subIndex % 2 === 1) {
                      let lines = text
                        .split("\n")
                        .filter((line) => line.trim() !== "");
                      let language;
                      let codeLines;

                      if (text.startsWith("\n")) {
                        language = "plaintext";
                        codeLines = lines;
                      } else {
                        [language, ...codeLines] = lines;
                      }
                      const code = codeLines.join("\n");
                      if (language === "html") {
                        return (
                          <div className="table-container">
                            <TableWithDownload>
                              <div
                                key={subIndex}
                                dangerouslySetInnerHTML={{ __html: code }}
                              />{" "}
                            </TableWithDownload>
                          </div>
                        );
                      } else if (
                        [
                          "bar",
                          "line",
                          "pie",
                          "doughnut",
                          "polarArea",
                          "radar",
                          "area",
                          "horizontalBar",
                        ].includes(language)
                      ) {
                        return (
                          <div className="chart-container" key={subIndex}>
                            {renderBarChart(code, language)}
                          </div>
                        );
                      } else if (language === "img") {
                        return (
                          <img
                            src={`${process.env.REACT_APP_API_ENDPOINT}/v1/get_image?image_name=${code}`}
                            alt={code}
                            style={{ maxHeight: "300px" }}
                          />
                        );
                      } else {
                        return (
                          <SyntaxHighlighter
                            key={subIndex}
                            language={language}
                            style={darcula}
                          >
                            {code}
                          </SyntaxHighlighter>
                        );
                      }
                    } else {
                      if (mailTrigger) {
                        text =
                          restOfMessage + "\n\n   ✉️ Mail has been drafted.";
                      }
                      return (
                        <p key={subIndex}>
                          {text}{" "}
                          {message.role !== "user" && (
                            <FaVolumeUp
                              className="read-out-icon"
                              onClick={() => handleReadOut(text)}
                            />
                          )}
                        </p>
                      );
                    }
                  })
                // )
                }
              </div>
              <div className="message-options-menu">
                <div className="message-options-icon">
                  <FaEllipsisV></FaEllipsisV>
                </div>
                <div className="message-options-dropdown">
                  <ul>
                    <li
                      title="Delete"
                      onClick={() => handleDeleteMessage(message.id)}
                    >
                      <FaRegTrashAlt />
                    </li>
                    <li title="Copy" onClick={() => UpcomingFeature()}>
                      <FaRegCopy />
                    </li>
                    <li title="Export" onClick={() => UpcomingFeature()}>
                      <FaRegShareSquare />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}

          <div ref={messageEndRef} />
        </div>
        <div className="input-suggestions">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="suggestion-bubble"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </div>
          ))}
        </div>

        <div className="chat-input">
          <div className="input-wrapper">
            <div className="upload-items-menu">
              <div className="upload-items-icon">
                <GoUpload />
              </div>
              <div className="upload-items-dropdown">
                <ul>
                  <li
                    title="Upload Image"
                    onClick={() => inputRef.current.click()}
                  >
                    <BiImageAdd />
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                      ref={(input) => (inputRef.current = input)}
                    />
                  </li>
                  <li title="Upload File" onClick={() => UpcomingFeature()}>
                    <LiaFileUploadSolid />
                  </li>
                </ul>
              </div>
            </div>
            <textarea
              ref={userInputRef}
              placeholder={DefaultTexts[language || "en"].InputPlaceholder}
              value={userInput}
              onChange={handleInputChange}
              onKeyPress={handleUserInput}
              style={{ height: textAreaHeight }}
              disabled={loading}
            ></textarea>
            <SendIcon loading={loading} />
          </div>

          <button
            className="speech-recognition-button"
            onClick={handleSpeechRecognition}
          >
            {isListening ? <FaStop /> : <LiaMicrophoneSolid />}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatWindow;
