import React, { useState, useEffect } from "react";
import "./App.css";
import { PublicClientApplication } from "@azure/msal-browser";
import ChatWindow from "./components/ChatWindow";
import SideNav from "./sidenav";
import InsightsComponent from "./components/InsightsComponent";
import NewsComponent from "./components/NewsComponent";
import OptimizeComponent from "./components/OptimizeComponent";
import { Client } from "@microsoft/microsoft-graph-client";
import ForbiddenPage from "./components/403/403";

function Header({ user }) {
  const userInitial = user?.name
    ? user.name
        .split(" ")
        .map((name) => name.charAt(0).toUpperCase())
        .reverse()
        .join("")
    : "";

  return (
    <div className="header">
      <h1 className="logo">DMA Copilot</h1>
      <div className="user-section">
        <div className="user-avatar">
          <div className="user-initial">{userInitial}</div>
          <span className="user-name">{user?.name}</span>
        </div>
        <div className="user-role">
          <select>
            <option value="manager">Category Manager</option>
            <option value="store_manager">Store Manager</option>
          </select>
        </div>
      </div>
    </div>
  );
}

const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority:
      "https://login.microsoftonline.com/30af61e6-f207-4ecc-97ac-2932bc0503dc",
    redirectUri: window.location.origin,
  },
};

const pca = new PublicClientApplication(msalConfig);

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [currentUI, setCurrentUI] = useState("chat");
  const [language, setLanguage] = useState(
    localStorage.getItem("dma_language") || "en"
  );

  useEffect(() => {
    pca.handleRedirectPromise().then((authResult) => {
      if (authResult && !authResult.error) {
        setAuthenticated(true);
        setUser(authResult.account);
      } else {
        // setLoading(false);
      }
    });

    const accounts = pca.getAllAccounts();
    if (accounts.length > 0) {
      setAuthenticated(true);
      setUser(accounts[0]);
      // setLoading(false);
    } else {
      pca.loginRedirect().catch((error) => {
        console.error("Authentication error:", error);
        // setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (authenticated) {
      const accounts = pca.getAllAccounts();
      const adventurerGroups = [
        "ai.Retail.DMA.Copilot.Team",
        "ai.Retail.DMA.Copilot.Users",
      ];

      if (accounts.length > 0) {
        const graphClient = Client.init({
          authProvider: async (done) => {
            const token = await pca.acquireTokenSilent({
              account: accounts[0],
              scopes: ["user.read", "Group.Read.All"],
            });
            done(null, token.accessToken);
          },
        });

        graphClient
          .api("/me/memberOf")
          .get()
          .then((response) => {
            const userGroups = response.value;
            const isMemberOfAllowedGroup = userGroups.some((group) =>
              adventurerGroups.includes(group.displayName)
            );

            if (isMemberOfAllowedGroup) {
              setIsAuthorized(isMemberOfAllowedGroup);
            }
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error getting user's groups:", error);
          });
      }
    }
  }, [authenticated]);

  function handleSignOut() {
    pca.logoutRedirect();
  }

  function handleMenuOptionClick(option) {
    setCurrentUI(option);
  }

  const [lighttheme, setShow] = React.useState();

  return (
    <div className={`App ${lighttheme ? "lighttheme" : ""}`}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          {!authenticated ? (
            <div>
              <p>Please sign in to continue.</p>
              <button onClick={pca.loginRedirect}>Sign In</button>
            </div>
          ) : (
            <React.Fragment>
              <div className="signout">
                <button onClick={handleSignOut}>Sign Out</button>
              </div>
              {isAuthorized ? (
                <React.Fragment>
                  <Header user={user} />

                  <SideNav
                    onMenuOptionClick={handleMenuOptionClick}
                    language={language}
                    lighttheme={lighttheme}
                    setShow={setShow}
                    setLanguage={setLanguage}
                  />

                  {currentUI === "insights" && (
                    <InsightsComponent user={user} />
                  )}
                  {currentUI === "news" && <NewsComponent user={user} />}
                  {currentUI === "optimize" && (
                    <OptimizeComponent user={user} />
                  )}
                  {currentUI === "chat" && (
                    <ChatWindow user={user} language={language} />
                  )}
                </React.Fragment>
              ) : (
                <ForbiddenPage />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default App;
