import {
  FaHome,
  FaChartBar,
  FaChartLine,
  FaNewspaper,
  FaTimes,
} from "react-icons/fa";
import { FcSettings } from "react-icons/fc";
import { MdInsights } from "react-icons/md";
import React, { useState } from "react";
import Modal from "react-modal";
import "./SettingsModal.css";

function SideNav({
  onMenuOptionClick,
  language,
  setLanguage,
  lighttheme,
  setShow,
}) {
  const [selectedOption, setSelectedOption] = useState("chat");
  const handleMenuOptionClick = (option) => {
    if (option === "settings") {
      setIsSettingsModalOpen(true); // Open settings modal
    } else {
      setSelectedOption(option);
      onMenuOptionClick(option);
    }
  };
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const closeModal = () => {
    setIsSettingsModalOpen(false); // Close settings modal
  };

  const [dataset, setDataset] = useState("default");

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    localStorage.setItem("dma_language", e.target.value);
  };

  const handleDatasetChange = (e) => {
    setDataset(e.target.value);
  };

  return (
    <nav className="side-nav">
      <ul>
        <li
          className={selectedOption === "chat" ? "selected" : ""}
          onClick={() => handleMenuOptionClick("chat")}
        >
          <FaHome />
          <span>Home</span>
        </li>
        <li
          className={selectedOption === "insights" ? "selected" : ""}
          onClick={() => handleMenuOptionClick("insights")}
        >
          <FaChartBar />
          <span>Insights</span>
        </li>
        <li className={selectedOption === "analyze" ? "selected" : ""}>
          <FaChartLine />
          <span>Analyze</span>
        </li>
        <li
          className={selectedOption === "news" ? "selected" : ""}
          onClick={() => handleMenuOptionClick("news")}
        >
          <FaNewspaper />
          <span>News</span>
        </li>
        <li
          className={selectedOption === "optimize" ? "selected" : ""}
          onClick={() => handleMenuOptionClick("optimize")}
        >
          <MdInsights />
          <span>Intelligence</span>
        </li>
      </ul>
      {/* "Cogs" icon for opening settings modal */}
      <div
        className="bottom-icon"
        onClick={() => handleMenuOptionClick("settings")}
      >
        <FcSettings />
      </div>

      {/* Modal for settings */}
      <Modal
        isOpen={isSettingsModalOpen}
        onRequestClose={closeModal}
        contentLabel="Settings Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-header">
          <h2>Settings</h2>
          <button onClick={closeModal}>
            <FaTimes />
          </button>
        </div>
        <div className="modal-content">
          <div className="setting-option">
            <label htmlFor="theme-switch">Theme:</label>
            <div className="toggle-container">
              <input
                type="checkbox"
                className="toggle"
                onClick={() => setShow(!lighttheme)}
                id="checkbox"
              />
              <label htmlFor="checkbox" className="checkbox-label">
                <p className="dark-text" title="Dark Theme">
                  {lighttheme ? "Dark" : "Light"}
                </p>
                <p className="light-text" title="Light Theme">
                  {lighttheme ? "Dark" : "Light"}
                </p>

                <span className="ball"></span>
              </label>
            </div>
          </div>
          <div className="setting-option">
            <label htmlFor="language">Default Language:</label>
            <div className="dropdown-select">
              <select
                id="language"
                value={language}
                onChange={handleLanguageChange}
              >
                <option value="en">English</option>
                <option value="ja">Japanese</option>
                <option value="zh">Chinese</option>
                <option value="es">Spanish</option>
                <option value="fr">French</option>
                <option value="it">Italian</option>
                <option value="hi">Hindi</option>
              </select>
            </div>
          </div>

          <div className="setting-option">
            <label htmlFor="dataset">Dataset:</label>
            <div className="dropdown-select">
              <select
                id="dataset"
                value={dataset}
                onChange={handleDatasetChange}
              >
                <option value="d1">dataset 1</option>
                <option value="d2">dataset 2</option>
              </select>
            </div>
          </div>
        </div>
      </Modal>
    </nav>
  );
}

export default SideNav;
