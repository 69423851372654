import React, { useEffect, useState } from 'react';
import './403.css';

const ForbiddenPage = () => {
  const [eyePosition, setEyePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    const pageX = window.innerWidth;
    const pageY = window.innerHeight;
    const mouseY = event.pageY;
    const mouseX = event.pageX;

    const xAxis = ((mouseX - pageX / 2) / pageX) * 30;
    const yAxis = ((mouseY - pageY / 2) / pageY) * 30;

    setEyePosition({ x: xAxis, y: yAxis });
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const { x: eyeX, y: eyeY } = eyePosition;


  return (
    <div className="box">
  <div className="box__ghost">
    <div className="symbol"></div>
    <div className="symbol"></div>
    <div className="symbol"></div>
    <div className="symbol"></div>
    <div className="symbol"></div>
    <div className="symbol"></div>
    
    <div className="box__ghost-container">
      <div className="box__ghost-eyes">
      <div
              className="box__eye-left"
              style={{ transform: `translate(${eyeX-30}px, ${eyeY-10}px)` }}
            ></div>
        <div
              className="box__eye-right"
              style={{ transform: `translate(${eyeX-30}px, ${eyeY-10}px)` }}
            ></div>
      </div>
      <div className="box__ghost-bottom">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div className="box__ghost-shadow"></div>
  </div>
  
  <div className="box__description">
    <div className="box__description-container">
      <div className="box__description-title">Whoops!</div>
      <div className="box__description-text">Seems like you've ventured off the digital grid.</div>
      <div className="box__description-text">Yearning for DMA Copilot action? Just hit us up for access - easy peasy!</div>
    </div>
    
    <a href="mailto:parmita.biswas@accenture.com?cc=avinesh.avileri@accenture.com&subject=Access%20Request%20|%20DMA%20Copilot&body=Hi%20Team,%0A%0AI'm%20requesting%20access%20to%20DMA%20Copilot%20for%20the%20following%20purpose.%0A%0APurpose:%20%5BProvide%20Your%20Purpose%5D%0A%0AThank%20you,%0A%5BYour%20Name%5D"  className="box__button">Request Access</a>
    
  </div>
  
</div>
  );
};

export default ForbiddenPage;
