import React from "react";  
import "./NewsComponent.css";  
  
function NewsComponent() {  
  const cars = [  
    {  
      id: 1,  
      title: "Walmart Tiny Stores",  
      description: "Walmart is testing cute tiny stores in vacation spots far from big boxes. The nations largest retailer is exploring new ways to cater to travelers.",  
      imageUrl: "/assets/images/News Img 3.png",  
      url: "https://www.forbes.com/retail/?sh=45ef31a54a82"  
    },
    {  
      id: 2,  
      title: "Monos - Generational Legacy Travel Brand",  
      description: "Monos is building a generational legacy travel brand. The luggage brand recently opened its first physical store.",  
      imageUrl: "/assets/images/News Img 2.png",  
      url: "https://www.retaildive.com/"  
    },
    {  
      id: 3,  
      title: "Capitalize on Data-Driven Environment",  
      description: "Why you need to capitalize on the rise of the data-driven environment ",  
      imageUrl: "/assets/images/News Img1.png",  
      url: "https://www.accenture.com/nl-en/blogs/insights/data-driven-enterprise"  
    }  
  ];  
  
  const handleCarClick = (url) => {  
    window.open(url, "_blank");  
  };  
  
  return (  
 
    <div className="news">  
      <h2>News</h2>  
      <div className="news-grey-box">
      <div className="news-car-grid">  
        {cars.map((car) => (  
          <div className="news-car-card" key={car.id} onClick={() => handleCarClick(car.url)}>  
            <img className="news-car-image" src={car.imageUrl} alt={car.title} />  
            <h3 className="news-car-title">{car.title}</h3>  
            <p className="news-car-description">{car.description}</p>  
          </div>  
        ))}  
      </div>  
    </div>  
    </div>
  );  
}  
  
export default NewsComponent;  
