import { Chart } from "chart.js";
import React, { useEffect, useRef, useState } from "react";
import "./Chart.css";

function BarChart({ chartDataString, chart }) {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const [selectedChartType, setSelectedChartType] = useState(chart);

  useEffect(() => {
    const chartData = JSON.parse(chartDataString.replaceAll("'", '"'));

    // Add or modify the fill property for the line dataset

    if (selectedChartType === "line") {
      chartData.datasets.forEach((dataset) => {
        dataset.fill = false; // Set fill to false for the line dataset
      });
    }

    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }

      const newChartInstance = new Chart(ctx, {
        type: selectedChartType !== "area" ? selectedChartType : "line",
        data: chartData,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
      chartInstanceRef.current = newChartInstance;
    }

    // Cleanup on component unmount
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartDataString, selectedChartType]);

  return (
    <div>
      <select
        value={selectedChartType}
        onChange={(e) => setSelectedChartType(e.target.value)}
        className="chart-types"
      >
        <option value="bar">Bar Chart</option>
        <option value="line">Line Chart</option>
        <option value="pie">Pie Chart</option>
        <option value="doughnut">Doughnut Chart</option>
        <option value="polarArea">Polar Area Chart</option>
        <option value="radar">Radar Chart</option>
        <option value="area">Area Chart</option>
        <option value="horizontalBar">Horizontal Bar</option>
      </select>
      <canvas ref={chartRef} className="chart-canvas" />
    </div>
  );
}

export default BarChart;
