// import { useCallback, useEffect } from "react";

// const EmailShareComponent = ({inputStr}) => {
// //   const inputStr = `
// //     send_mail('parmita.biswas@accenture.com', 'Total Sales for Each Quarter of 2022', 'Here are the total sales for each quarter of 2022:<br><br><table border=1 cellpadding=5 cellspacing=0><thead><tr><th>Quarter</th><th>Total Sales</th></tr></thead><tbody><tr><td>Q1</td><td>$1,042,527,741.86</td></tr><tr><td>Q2</td><td>$1,099,655,918.74</td></tr><tr><td>Q3</td><td>$1,084,539,450.53</td></tr><tr><td>Q4</td><td>$1,164,540,061.12</td></tr></tbody></table><br>These are the total sales for each quarter of 2022.')
// //   `; // Replace this with your actual input string

//   const extractDetails = (inputStr) => {
//     const matches = inputStr.match(/'([^']+)'/g);
//     const recipient = matches[0].replace(/'/g, "");
//     const subject = matches[1].replace(/'/g, "");
//     const body = matches[2].replace(/'/g, "");

//     return { recipient, subject, body };
//   };

//   const sendEmail = useCallback(() => {
//     const { recipient, subject, body } = extractDetails(inputStr);
//     const recipientEncoded = encodeURIComponent(recipient);
//     const subjectEncoded = encodeURIComponent(subject);
//     const bodyEncoded = encodeURIComponent(body);

//     const mailtoLink = `mailto:${recipientEncoded}?subject=${subjectEncoded}&body=${bodyEncoded}`;
//     window.location.href = mailtoLink;
//   }, [inputStr]);

//   useEffect(() => {
//     sendEmail();
//   }, [sendEmail]);

//   return (
//     <div>
//       <button onClick={sendEmail}>Share via Email</button>
//     </div>
//   );
// };

// export default EmailShareComponent;

// import { useCallback } from "react";

const sendEmail = (inputStr) => {
  const matches = inputStr.match(/'([^']+)'/g);
  const recipient = matches[0].replace(/'/g, "");
  const subject = matches[1].replace(/'/g, "");
  const body = matches[2].replace(/'/g, "");

  const recipientEncoded = encodeURIComponent(recipient);
  const subjectEncoded = encodeURIComponent(subject);
  
  const formattedBody = body.replace(/\\n/g, "\n");
  const bodyEncoded = encodeURIComponent(formattedBody);

  const mailtoLink = `mailto:${recipientEncoded}?subject=${subjectEncoded}&body=${bodyEncoded}`;
  window.location.href = mailtoLink;
};

export default sendEmail;
