const DefaultTexts = {
  en: {
    Greeting: "Hello",
    WelcomeMessage:
      "How can I help you with your retail-related questions? Please feel free to ask anything about products, sales, or customer information.",
    NewChatName: "New Chat",
    History: "History",
    InputPlaceholder: "Type your message here",
    SampleQuestions: [
      "Top 5 product categories in terms of sales?",
      "What's my total sales for each quarter of 2022?",
      "What are the different product categories and what are their values?",
    ],
  },
  ja: {
    Greeting: "こんにちは、",
    WelcomeMessage:
      "小売関連の質問にはどのように対応できますか?商品や販売、お客様情報など、何でもお気軽にお問い合わせください。",
    NewChatName: "新しいチャット",
    History: "歴史",
    InputPlaceholder: "ここにメッセージを入力してください",
    SampleQuestions: [
      "売上高トップ 5 の製品カテゴリは?",
      "2022 年の各四半期の総売上高はいくらですか?",
      "さまざまな製品カテゴリとその価値は何ですか?",
    ],
  },
  es: {
    Greeting: "Hola",
    WelcomeMessage:
      "¿Cómo puedo ayudarle con sus preguntas relacionadas con el comercio minorista? No dude en preguntar cualquier cosa sobre productos, ventas o información del cliente.",
    NewChatName: "Nueva conversación",
    History: "Historia",
    InputPlaceholder: "Escribe tu mensaje aquí",
    SampleQuestions: [
      "¿Las 5 categorías de productos principales en términos de ventas?",
      "¿Cuáles son mis ventas totales para cada trimestre de 2022?",
      "¿Cuáles son las diferentes categorías de productos y cuáles son sus valores?",
    ],
  },
  hi: {
    Greeting: "नमस्ते",
    WelcomeMessage:
      "मैं आपके खुदरा-संबंधी प्रश्नों में आपकी कैसे सहायता कर सकता हूँ? कृपया बेझिझक उत्पादों, बिक्री या ग्राहक जानकारी के बारे में कुछ भी पूछें।",
    NewChatName: "नई चैट",
    History: "इतिहास",
    InputPlaceholder: "यहाँ आपके संदेश लिखें",
    SampleQuestions: [
      "बिक्री के मामले में शीर्ष 5 उत्पाद श्रेणियां?",
      "2022 की प्रत्येक तिमाही के लिए मेरी कुल बिक्री क्या है?",
      "विभिन्न उत्पाद श्रेणियाँ क्या हैं और उनके मूल्य क्या हैं?",
    ],
  },
  zh: {
    Greeting: "你好",
    WelcomeMessage:
      "我如何帮助您解决与零售相关的问题？请随时询问有关产品、销售或客户信息的任何问题。",
    NewChatName: "新聊天",
    History: "历史",
    InputPlaceholder: "在这里输入你的消息",
    SampleQuestions: [
      "销量排名前 5 的产品类别？",
      "我 2022 年每个季度的总销售额是多少？",
      "有哪些不同的产品类别以及它们的价值是什么？",
    ],
  },
  fr: {
    Greeting: "Bonjour",
    WelcomeMessage:
      "Comment puis-je vous aider avec vos questions concernant la vente au détail ? N'hésitez pas à poser des questions sur les produits, les ventes ou les informations sur les clients.",
    NewChatName: "Nouvelle discussion",
    History: "Histoire",
    InputPlaceholder: "tapez votre message ici",
    SampleQuestions: [
      "Top 5 des catégories de produits en termes de ventes ?",
      "Quel est mon chiffre d'affaires total pour chaque trimestre 2022 ?",
      "Quelles sont les différentes catégories de produits et quelles sont leurs valeurs ?",
    ],
  },
  it: {
    Greeting: "Ciao",
    WelcomeMessage:
      "Come posso aiutarti con le tue domande relative alla vendita al dettaglio? Non esitate a chiedere qualsiasi cosa su prodotti, vendite o informazioni sui clienti.",
    NewChatName: "Nuova chiacchierata",
    History: "Storia",
    InputPlaceholder: "Scrivi qui il tuo messaggio",
    SampleQuestions: [
      "Le prime 5 categorie di prodotti in termini di vendite?",
      "Qual è il mio fatturato totale per ogni trimestre del 2022?",
      "Quali sono le diverse categorie di prodotti e quali sono i loro valori?",
    ],
  },
};

export default DefaultTexts;
