import React from "react";  
import "./OptimizeComponent.css";  
  
function OptimizeComponent() {  
  const cars = [  
    {  
      id: 1,  
      title: "Fashion Optimization",  
      description: "This dashboard helps the Store Managers or Category Managers to analyze future product demand through historical sales data, offering cluster-level analysis with drill-down to stores and product category level for deeper insights, culminating in product attribute recommendations for improved sales profits.",  
      imageUrl: "/assets/images/Fashion Assortment Optimization.png",  
      url: "https://app.powerbi.com/groups/3109489e-3aa6-4421-ac50-46098c7f5624/reports/e3b4d92c-b2bf-4137-9b6e-094b8815c86f/ReportSection0ae037e0bebcd141a086?experience=power-bi"  
    },
    {  
      id: 2,  
      title: "Fashion Forecasting",  
      description: "This dashboard helps to assist fashion retailers and brands in predicting, planning for, and efficiently distributing their products based on anticipated consumer demand. It integrates data-driven insights and predictive analytics to enable businesses to make informed decisions about their inventory, production, and distribution strategies.",  
      imageUrl: "/assets/images/Fashion Demand Forecasting.png",  
      url: "https://ai-retail-localization-assortment-harvest.azurewebsites.net/login"  
    }, 
    {  
      id: 3,  
      title: "Grocery Assortment Optimization",  
      description: "This dashboard is used by Category Managers in the grocery industry to strategically manage their product offerings in various stores. It explains data analysis, segmentation, and predictive modeling which helps them to make informed decisions about which products to stock in each store. It helps in optimizing the assortment recommending the best products for each store.",  
      imageUrl: "/assets/images/Grocery Assortment Optimization.png",  
      url: "https://ai-retail-ui-customer-led-approach-prod.azurewebsites.net/overview"  
    },
    {  
      id: 4,  
      title: "Grocery Space Optimization",  
      description: "This dashboard is designed to help retailers in the grocery industry to effectively manage and maximize the use of physical retail space within their stores. This integrates various data sources and analytical insights to improve store layout, product placement, and overall space utilization.",  
      imageUrl: "/assets/images/Grocery Space Optimization.png",  
      url: "https://app.powerbi.com/groups/me/reports/7a515471-a23a-43bc-a7ef-257778296acb?ctid=e0793d39-0939-496d-b129-198edd916feb&experience=power-bi"  
    },
    {  
      id: 5,  
      title: "Demand Planning",  
      description: "Empower demand planners with a comprehensive view of forecasted values, enabling them to compare forecasted data with actual figures, analyze demand drivers, and identify influential factors. This solution also offers the capability to explore forecast comparison metrics, providing valuable insights.",  
      imageUrl: "/assets/images/Demand_Planning.png",  
      url: "https://orange-bay-0c91d660f.3.azurestaticapps.net/"  
    },

  ];  
  
  const handleCarClick = (url) => {  
    window.open(url, "_blank");  
  };  
  
  return (  
    <div className="optimize">  
      <h2>Intelligence</h2>  
      <div className="optimize-grey-box">
      <div className="optimize-car-grid">  
        {cars.map((car) => (  
          <div className="optimize-car-card" key={car.id} onClick={() => handleCarClick(car.url)}>  
            <img className="optimize-car-image" src={car.imageUrl} alt={car.title} />  
            <h3 className="optimize-car-title">{car.title}</h3>  
            <p className="optimize-car-description">{car.description}</p>  
          </div>  
        ))}  
      </div>  
    </div>  
    </div>
  );  
}  
  
export default OptimizeComponent;  
