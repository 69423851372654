import React from "react";  
import "./InsightsComponent.css";  
  
function InsightsComponent() {  
  const cars = [  
    {  
      id: 1,  
      title: "Performance Summary",  
      description: "This dashboard explains about last week sales performance and trends, current and go-forward inventory positions and compare to time frames and product areas.",  
      imageUrl: "/assets/images/Performance Summary.png",  
      url: "https://app.powerbi.com/groups/3109489e-3aa6-4421-ac50-46098c7f5624/reports/a2ab91f1-9be1-443b-8d63-2e27c723f641/ReportSection9b5bc0a4d0c407407689?experience=power-bi"  
    },  
    {  
      id: 2,  
      title: "Style Hindsight",  
      description: "This dashboard provides visibility to get summarized results across associated attributes and also support ability to identify commonalities across style performance",  
      imageUrl: "/assets/images/Style Hindsight.png",  
      url: "https://app.powerbi.com/groups/3109489e-3aa6-4421-ac50-46098c7f5624/reports/bcebead6-f99d-435f-a626-37d751a70747/ReportSectiondf033c37a53a36297928?experience=power-bi"  
    },  
    {  
      id: 3,  
      title: "Assortment Performance",  
      description: "This dashboard identifies what components of the assortment drove performance with comparisions to different time frames and product areas.",  
      imageUrl: "/assets/images/Assortment Performance.png",  
      url: "https://app.powerbi.com/groups/3109489e-3aa6-4421-ac50-46098c7f5624/reports/3a605eee-4480-426d-944a-f3200c93dd4b/ReportSection9b5bc0a4d0c407407689?experience=power-bi"  
    },
    {  
      id: 4,  
      title: "PO Status",  
      description: "This dashboard provides visibility into on-order inventory and DC inventory to understand available actions. It also helps to understand the impact of planned receipt month vs anticipated receipt month of on-order goods on store inventory.",  
      imageUrl: "/assets/images/PO Status.png",  
      url: "https://app.powerbi.com/groups/3109489e-3aa6-4421-ac50-46098c7f5624/reports/33b5ad06-7873-4d3e-8ea9-f16af1e6c9ff/ReportSectionadf6a2cbbc17bcbb7ac0?experience=power-bi"  
    },
    {  
      id: 5,  
      title: "DC Aged",  
      description: "This dashboards provided weekly summary of DC on-hand inventory and its assoicated age based on the scheduled releases. It also highlights risks that exists in the inventory position to quickly prompts action.",  
      imageUrl: "/assets/images/DC Aged.png",  
      url: "https://app.powerbi.com/groups/me/reports/96bdb20d-5678-42c6-b1a2-d5127b18023a/ReportSection9ff03f8845e473097965?ctid=e0793d39-0939-496d-b129-198edd916feb&experience=power-bi"  
    } ,
    { 
      id: 6,  
      title: "Stock In Transit",  
      description: "The stock in-transit dashboard provides a comprehensive overview of the status of goods in transit from suppliers which helps in real time inventory management. This real-time visibility aids in identifying potential delays, improving supply chain efficiency, and ensuring on-time delivery.",  
      imageUrl: "/assets/images/Stock In Transit.png",  
      url: "https://app.powerbi.com/groups/me/reports/0b80561d-ceb6-4f76-9c31-1480a219feb7/ReportSection?ctid=e0793d39-0939-496d-b129-198edd916feb&experience=power-bi"  
    },
    {  
      id: 7,  
      title: "Store Visibility",  
      description: "This dashboard offers in-depth performance analysis of various cateogories and products. It leverages cluster level data exploration to gain deeper insights and supports drill down capabilities for specific store and store cluster.",  
      imageUrl: "/assets/images/Store Visibility.png",  
      url: "https://app.powerbi.com/groups/me/reports/9edefce4-600e-4628-92ec-7a358400430a/ReportSection907e4930d796d6067958?ctid=e0793d39-0939-496d-b129-198edd916feb&experience=power-bi"  
    },
    {  
      id: 8,  
      title: "Product Visibility",  
      description: "This dashboard enables user to perform sales analysis at product category level and enables them to drill down to explore all product IDs within each category, facilitating a thorough understanding of product performance and trends. ",  
      imageUrl: "/assets/images/Product Visibility.png",  
      url: "https://app.powerbi.com/groups/me/reports/7f4b7286-289f-461c-b684-598dd258dfc8/ReportSection2dbf062f2e9a0aa5901c?ctid=e0793d39-0939-496d-b129-198edd916feb&experience=power-bi"  
    }
  ];  
  
  const handleCarClick = (url) => {  
    window.open(url, "_blank");  
  };  
  
  return (  
    <div className="insights">  
      <h2>Insights</h2>  
      <div className="insights-grey-box">
      <div className="insights-car-grid">  
        {cars.map((car) => (  
          <div className="insights-car-card" key={car.id} onClick={() => handleCarClick(car.url)}>  
            <img className="insights-car-image" src={car.imageUrl} alt={car.title} />  
            <h3 className="insights-car-title">{car.title}</h3>  
            <p className="insights-car-description">{car.description}</p>  
          </div>  
        ))}  
      </div>  
    </div>  
    </div>
  );  
}  
  
export default InsightsComponent;  
